<script setup lang="ts">

const { refsToFormData } = useFormData()

const email = ref('')

const pending = ref(false)
const fetchError = ref<string|null>(null)
const success = ref(false)

let successTimeout: NodeJS.Timeout

async function onSubmit() {
  if (email.value === '') {
    return
  }

  pending.value = true
  fetchError.value = null

  const {error} = await useApiFetch('/newsletter/subscribe', { method: 'post', body: refsToFormData({email}) })

  pending.value = false

  fetchError.value = error.value ? useNuxtApp().$i18n.t('_global.error') : null
  success.value = error.value === null

  if (success.value) {
    clearTimeout(successTimeout)
    successTimeout = setTimeout(() => {
      success.value = false
      fetchError.value = null
    }, 2500)
  }
}

watch(email, () => {
  fetchError.value = null
})
</script>

<template>
  <div class="basis-[420px] grow">
    <form v-if="!success" class="flex flex-wrap gap-2" :class="{'opacity-50 pointer-events-none': pending}" @submit.prevent="onSubmit">
      <p class="w-full">{{ $t('footer.newsletter.subscribe') }}</p>
      <AtomsFormTextInput name="email"
                          type="email"
                          placeholder="albert@einstein.genius"
                          class="grow max-w-sm"
                          wrapper-class="flex"
                          input-class="!mb-0"
                          :required="true"
                          v-model="email"/>
      <AtomsButton :label="$t('_global.sign_up')" size="small" tag="button" hover-scheme="default-inverted" />
      <AtomsFormFieldError :text="fetchError" class="!mt-0 w-full" />
    </form>
    <div v-else class="max-w-sm">
      <AtomsFormSuccess :text="$t('_global.thanks')"/>
    </div>
  </div>
</template>